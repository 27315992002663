<template>
  <div class="Invoices">
    <div
      :class="['row row--align-center row--justify-between', {
        'mb-30': user.client && !mobile.isMobile,
        'mb-0 ml-16': mobile.isMobile,
      }]"
    >
      <!-- "Invoices waiting to be approved"/"Invoices that have been funded" -->
      <label
        v-if="mobile.isMobile"
        class="fc-light fs-14 mt-15 mb-15"
      >
        {{ mobileHelperText }}
      </label>
    </div>

    <router-view />
  </div>
</template>

<script>
export default {
  name: 'Invoices',

  async created () {
    this.user = JSON.parse(localStorage.getItem('user'))
  },

  data () {
    return {
      keyBuffer: [],
      secretUniversalShortcut: false,
      user: null,
    }
  },

  computed: {
    mobile () {
      return this.$store.getters.mobile
    },

    mobileHelperText () {
      if (this.$route.name === 'pending') return 'Invoices waiting to be approved'
      if (this.$route.name === 'approved') return 'Invoices that have been funded'
      if (this.$route.name === 'declined') return 'Invoices declined before being approved'
      if (this.$route.name === 'paid') return 'Invoices with payments by debtors'
      return null
    },
  },
}
</script>

<style lang="sass">
.Invoices
  position: relative
  width: 100%

  @media #{$tablet-landscape-and-down}
    .Table
      margin-top: 0
</style>
